body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #333;
}

.container {
  background-color: #fff;
  padding: 30px 50px;
  border-radius: 15px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;
  animation: fadeIn 1s ease-in-out;
  position: relative; 
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  margin-left: 60px;
}

.linkedin,
.twitter {
  display: flex;
  justify-content: center;
  align-items: center; 
  margin: 0 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(135deg, #0077b5, #005582);
  text-align: center; 
  color: #fff;
  font-size: 1em; 
}

.header {
  margin-bottom: 30px;
}

.header h1 {
  margin: 0;
  font-size: 2.5em;
  color: #222;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.header .subscript {
  font-size: 0.5em;
  color: #666;
  display: block;
}

.header p {
  color: #555;
  margin: 10px 0 0;
  font-size: 1.1em;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  text-decoration: none;
  color: #fff;
  padding: 12px 25px;
  border-radius: 25px;
  margin: 10px 0;
  font-size: 1.3em;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  position: relative;
}

.link .svg-inline--fa {
  margin-right: 15px;
  font-size: 1.5em;
}

.link:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.email { background: linear-gradient(135deg, #D93025, #EA4335);}
.instagram { background: linear-gradient(135deg, #e4405f, #cc3652); }
.github { background: linear-gradient(135deg, #333, #24292e); }
.portfolio { background: linear-gradient(135deg, #4CAF50, #45a049); }
.phone { background: linear-gradient(135deg, #4CAF50, #45a049); }
.discord { background: linear-gradient(135deg, #7289DA, #5865F2); }


.instagram.hovered {
  background: #fff;
  color: #e4405f;
  border: 2px solid #e4405f;
}



.dark-mode {
  background-color: #333 !important;
  color: #fff;
}

.light-mode {
  background-color: #fff !important;
  color: #333;
}

.header {
  padding: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.mode-switch {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  padding: 5px 30px;
  background-color: #ccc;
  border-radius: 20px;
}

.slider {
  position: absolute;
  top: -1px;
  left: 0px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #807e7e;
  transition: transform 0.6s;
}

.dark-mode .mode-switch {
  background-color: #666;
}

.dark-mode .slider {
  transform: translateX(110px);
}

.mode-switch-text {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  color: #333;
}

.dark-mode .mode-switch-text {
  left: 26px;
  color: #fff;
}

.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
  flex-wrap: wrap;
}

.cookie-consent p {
  margin: 0;
  text-align: center;
  max-width: 80%;
  flex: 1 1 auto; 
}

.cookie-consent button {
  margin: 5px;
  padding: 5px 10px;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cookie-consent button:hover {
  background-color: #ddd;
}

.cookie-consent .yes-button:hover {
  background-color: green;
  color: white;
}

.cookie-consent .no-button:hover {
  background-color: red;
  color: white;
}

@media (max-width: 600px) {
  .cookie-consent {
    padding: 8px;
  }

  .cookie-consent button {
    padding: 5px 8px;
    font-size: 14px;
  }

  .cookie-consent p {
    font-size: 14px;
  }
}
